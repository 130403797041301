import { Link } from "@remix-run/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { appLink, cn } from "~/utils/helper";

export default function DownloadAppBtn({ className }: { className?: string }) {
  let { t } = useTranslation();
  const [link, setLink] = useState("");

  useEffect(() => {
    setLink(appLink());
  }, []);

  return (
    <a
      className={cn("btn whitespace-nowrap", className)}
      href={link}
      target="_blank"
    >
      {t("download app")}
    </a>
  );
}
